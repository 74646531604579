<template>
  <el-card class="paragraph-config">
    <div class="body-right" v-if="config && (bantchActTab === 'style' || tabIndex === 0)">
      <el-tabs v-model="actComponent">
        <el-tab-pane style="min-height: 600px" label="文字样式" name="font_style" lazy>
          <BaseStyle :extra_font_list="styleData.extra_font_list" :styleData="styleData" v-model="config.style._self" :placeholder="placeholder.style._self">
          </BaseStyle>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="node-select-container" v-else-if="config && bantchActTab === 'node' && tabIndex === 1">
      <div class="node-condition-container">
        <div class="search-container">
          <el-input
            clearable
            placeholder="请输入关键词"
            class="search-input"
            size="mini"
            prefix-icon="el-icon-search"
            v-model="conditions.listKeyword">
          </el-input>
        </div>
        <div>
          <el-badge :value="this.config.node_id_list.length" type="primary" class="item">
            <el-popover
              popper-class="style-demo-paragraph-config-pop"
              placement="bottom"
              width="400"
              trigger="click">
                <div class="style-demo-paragraph-config-pop-list">
                  <div class="pop-header">
                    <div class="pop-title">已加入节点</div>
                    <div class="pop-control">
                      <el-button type="text" class="icon" @click="config.node_id_list=[]" :disabled="selectedList.length === 0">一键清空</el-button>
                    </div>
                  </div>
                  <div class="pop-list" v-if="selectedList.length > 0">
                    <div class="pop-list-item" v-for="item in selectedList" :key="item.node_id">
                      <div v-html="latexParse(getItem(item))" class="content"></div>
                      <el-button type="text" @click="delNode(item.node_id)"><i class="el-icon-delete" />删除</el-button>
                    </div>
                  </div>
                  <div v-else class="empty">列表为空~~</div>
                </div>
                <el-button type="primary" plain slot="reference" class="pop-toggle">已选</el-button>
            </el-popover>
          </el-badge>
        </div>
      </div>
      <div class="list">
        <div class="list-header">
          <div class="list-header-left">
          </div>
          <div class="list-header-right">
            <el-select v-model="conditions.level" placeholder="选择段落等级" :popper-append-to-body="false" clearable class="style-tool-select m-r-10" style="width: 120px;">
              <el-option label="一级段落" :value="1"></el-option>
              <el-option label="二级段落" :value="2"></el-option>
              <el-option label="三级段落" :value="3"></el-option>
              <el-option label="四级段落" :value="4"></el-option>
              <el-option label="五级段落" :value="5"></el-option>
              <el-option label="六级段落" :value="6"></el-option>
            </el-select>
            <el-button class="style-tool-button--text" @click="showPath=!showPath" :class="{'main-color': showPath}">
              <svg-icon name="eye" v-if="showPath"></svg-icon>
              <svg-icon name="close-eye" v-else></svg-icon>
              段落层级</el-button>
            <el-checkbox v-model="checkedAll" style="margin-right: 4px">全选筛选结果</el-checkbox>
            <el-checkbox v-model="checked">全选当前页</el-checkbox>
          </div>
        </div>
        <div class="list-item" v-for="item in listData.list" :key="item.node_id">
          <el-tooltip content="Right center" placement="right" effect="light" :open-delay="100" popper-class="style-tool-tooltip">
            <div slot="content" class="node-batch-dialog-preview">
              <div style="max-width: 400px;">
                <div class="content-body">
                  <span v-html="latexParse(item.content.body)"></span>
                </div>
              </div>
            </div>
            <el-checkbox :value="config.node_id_list.includes(item.node_id)" :label="true" @click.native.prevent="handleNodeList(item.node_id, item)" style="outline: none">
              <span v-html="latexParse(getItem(item))"></span>
            </el-checkbox>
          </el-tooltip>
          <div class="list-item-path" v-show="showPath" :title="item.path" v-html="item.path"></div>
        </div>
      </div>
      <div class="footer">
        <el-pagination background ayout="prev, pager, next"
          :total="listData.total" :page-size="10" :current-page.sync="conditions.currentPage"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      :title="this.actItem ? '修改段落配置' : '新增段落配置'"
      @closed="dialogClose"
      destroy-on-close
      :visible.sync="dialogVisible"
      width="600px">
      <el-form :model="form" :rules="rules" ref="form" label-width="140px">
        <el-form-item label="请输入配置名称：" prop="name">
          <el-input placeholder="请输入名称" style="width: 400px;" size="small" v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center">
        <el-button type="primary" @click="confirm" size="mini">{{ this.actItem ? '确认修改' : '确认创建' }}</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { defaultFormat } from './assets/format.js';
import BaseStyle from '../components/BaseStyle';
import { defaultParagraph } from '@/scripts/styleToolDefault';
import { mergeDefault, mergePeer, setId } from '@/scripts/helper';
import { parser } from '@/scripts/convertLatex';
import { iterateNode } from '@/scripts/treeHelper';
import _ from 'lodash';
import { catalog } from '../assets/componentCatalog.js';
import { fontMargin } from '@/assets/styleTool/map';

export default {
  components: {
    BaseStyle
  },
  props: {
    styleData: {
      type: Object,
      required: true
    },
    json: {
      type: Array,
      required: true
    }
  },
  created () {
    this.watchTab();
  },
  data () {
    return {
      fontMargin,
      dialogVisible: false,
      actItem: null,
      latexParse: parser,
      form: {
        name: ''
      },
      rules: {
        name: [{ validator: this.validator, trigger: 'change' }]
      },
      borderType: {
        solid: '实线',
        none: '无',
        dashed: '短虚线'
      },
      borderWeight: {
        1: '1（极粗）',
        2: '2',
        3: '3（粗）',
        4: '4',
        5: '5（默认）',
        6: '6',
        7: '7（细）',
      },
      tabIndex: 0,
      bantchActTab: 'style',
      defaultFormat: defaultFormat(),
      closed: false,
      showPath: false,
      tabs: [
        { name: '段落整体配置', index: 0 },
        { name: '段落批量配置', index: 1 },
      ],
      config: null,
      placeholder: null,
      actFormat: {
        data: {}
      },
      condition: {
        formatModel: '',
        search: ''
      },
      formatData: [],
      actComponent: 'font_style',
      styleClasses: [
        { name: '段落整体配置', index: '0', node_type: 'paragraph' },
      ],
      actStyle: {},
      conditions: {
        listKeyword: '',
        currentPage: 1,
        level: ''
      }
    };
  },
  computed: {
    checked: {
      get () {
        const nodeIdList = this.config.node_id_list;
        return this.listData.list.every(item => nodeIdList.includes(item.node_id));
      },
      set (val) {
        const { list } = this.listData;
        if (list.length === 0) return;
        const nodeIdList = this.config.node_id_list;
        if (val) {
          list.forEach(item => {
            !nodeIdList.includes(item.node_id) && nodeIdList.push(item.node_id);
          });
        } else {
          list.forEach(item => {
            const index = nodeIdList.indexOf(item.node_id);
            if (index >= 0) nodeIdList.splice(index, 1);
          });
        }
      }
    },
    checkedAll: {
      get () {
        const nodeIdList = this.config.node_id_list;
        return this.listData.filterList.every(item => nodeIdList.includes(item.node_id));
      },
      set (val) {
        const { filterList } = this.listData;
        if (filterList.length === 0) return;
        const nodeIdList = this.config.node_id_list;
        if (val) {
          filterList.forEach(item => {
            !nodeIdList.includes(item.node_id) && nodeIdList.push(item.node_id);
          });
        } else {
          filterList.forEach(item => {
            const index = nodeIdList.indexOf(item.node_id);
            if (index >= 0) nodeIdList.splice(index, 1);
          });
        }
      }
    },
    selectedList () {
      const nodeList = [];
      for (const { node } of iterateNode(this.json)) {
        if (node.node_type === 'paragraph') nodeList.push(node);
      }
      const nodeIdList = this.config.node_id_list;
      const selectedList = nodeIdList.map(item => {
        const node = _.cloneDeep(nodeList.find(node => node.node_id === item));
        if (!node) return;
        const content = node.content;
        const div = document.createElement('div');
        div.innerHTML = content.body;
        content._body = div.innerText;
        return Object.assign(node, { content });
      });
      return selectedList.filter(item => item);
    },
    listData () {
      if (!this.json) {
        return {
          list: [],
          filterList: [],
          total: 0
        };
      }
      const filterData = [];
      const { listKeyword, currentPage, level } = this.conditions;
      const json = _.cloneDeep(this.json);
      for (const { node, parent } of iterateNode(json)) {
        node.path = parent.path ? `${parent.path} - ` + (parent?.node_name || '') : (parent?.node_name || '');
        if (node.node_type === 'paragraph') {
          const { body, level: nodeLevel } = node.content;
          if ((!listKeyword || body?.indexOf(listKeyword) >= 0 || node.path.indexOf(listKeyword) >= 0) && (!level || nodeLevel === level)) {
            const body = node.content.body;
            const temp = document.createElement('div');
            temp.innerHTML = body;
            node.content._body = listKeyword ? temp.innerText.replace(listKeyword, `<span class="style-tool-mark">${listKeyword}</span>`) : temp.innerText;
            node.path = listKeyword ? node.path.replace(listKeyword, `<span class="style-tool-mark">${listKeyword}</span>`) : node.path;
            if (listKeyword) {
              node.content.body && (node.content.body = node.content?.body.replace(listKeyword, `<span class="style-tool-mark">${listKeyword}</span>`));
            }
            filterData.push(node);
          }
        }
      }
      const total = filterData.length;
      return {
        total,
        filterList: filterData,
        list: filterData.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10),
      };
    },
    paragraphBatch () {
      const { node_batch_cfg_list } = this.styleData.components[0];
      return node_batch_cfg_list?.filter(item => item.node_type === 'paragraph') || [];
    },
    formatList () {
      const formatList = catalog.module.paragraph.model;
      if (!formatList) return [];
      return formatList;
    },
    formatDataList () {
      const { search } = this.condition;
      return this.formatData.filter(item => item.data.name.indexOf(search) >= 0);
    },
  },
  methods: {
    changeDisplay (val) {
      if (val === 'block' || val === 'float') {
        this.config.html._self.img.align = 'left';
      } else {
        this.config.html._self.img.align = '';
      }
    },
    selectFormat (item) {
      this.actFormat = item;
      Object.assign(this.config, _.cloneDeep(item.data.data));
    },
    setComponent () {
      const config = _.cloneDeep(this.config);
      delete config.name;
      delete config.id;
      delete config.node_id_list;
      delete config.node_type;
      delete config.identity;
    },
    delNode (node_id) {
      const nodeIdList = this.config.node_id_list;
      const index = nodeIdList.indexOf(node_id);
      nodeIdList.splice(index, 1);
    },
    getItem (item) {
      return item?.content?._body;
    },
    handleNodeList (nodeId) {
      const nodeList = this.config.node_id_list;
      const index = nodeList.indexOf(nodeId);
      if (index >= 0) {
        nodeList.splice(index, 1);
      } else {
        nodeList.push(nodeId);
      }
    },
    confirm () {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        const { node_batch_cfg_list } = this.styleData.components[0];
        if (this.form.id) {
          const index = node_batch_cfg_list.findIndex(item => item.id === this.form.id);
          node_batch_cfg_list.splice(index, 1, this.form);
          this.actStyle = this.config = this.form;
        } else {
          const item = defaultParagraph({ id: setId(), name: this.form.name, node_id_list: [], node_type: 'paragraph' });
          node_batch_cfg_list.push(item);
          this.actStyle = this.config = item;
        }
        this.dialogVisible = false;
      });
    },
    editItem (val) {
      this.form = _.cloneDeep(val);
      this.dialogVisible = true;
    },
    delItem (val) {
      this.$confirm('此操作将永久删除该配置, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { node_batch_cfg_list } = this.styleData.components[0];
        const index = node_batch_cfg_list.findIndex(item => item.id === val.id);
        const paragraphBatchIndex = this.paragraphBatch.findIndex(item => item.id === val.id);
        node_batch_cfg_list.splice(index, 1);
        if (val.id === this.actStyle.id) {
          paragraphBatchIndex > 0 ? this.switchStyleBatch(paragraphBatchIndex[index - 1]) : this.switchStyleBatch();
        }
      }).catch(e => e);
    },
    validator (rule, value, callback) {
      if (!value) {
        return callback(new Error('名字不能为空!'));
      }
      const repeatName = this.paragraphBatch.find(item => item.name === value && item.id !== this.form.id);
      if (repeatName) return callback(new Error('名字已重复!'));
      callback();
    },
    dialogClose () {
      this.form = { name: '' };
    },
    addConfig () {
      this.dialogVisible = true;
    },
    watchTab () {
      if (this.tabIndex === 0) {
        this.switchStyleClass({ name: '段落整体配置', index: '0', node_type: 'paragraph' });
      } else {
        this.queryBatch();
      }
    },
    queryBatch () {
      const { node_batch_cfg_list } = this.styleData.components[0];
      if (!node_batch_cfg_list) this.$set(this.styleData.components[0], 'node_batch_cfg_list', []);
      this.switchStyleBatch();
    },
    switchStyleBatch (val) {
      if (!this.paragraphBatch.length) {
        this.actStyle = {};
        this.config = null;
        return;
      }
      this.conditions = {
        listKeyword: '',
        currentPage: 1,
      };
      if (val) {
        if (val.id === this.actStyle.id) return;
        this.actStyle = this.paragraphBatch.find(item => item.id === val.id);
      } else {
        this.actStyle = this.paragraphBatch[0];
      }
      this.config = this.actStyle;
      const { class_cfg_list, default_style } = this.styleData.components[0];
      const paragraphClass = class_cfg_list.find(item => item.identity.node_type === 'paragraph');
      this.placeholder = mergePeer(mergeDefault(defaultParagraph(), default_style), paragraphClass);
    },
    switchStyleClass (val) {
      const { node_type, name } = val;
      if (name === this.actStyle.name) return;
      const { class_cfg_list, default_style } = this.styleData.components[0];
      let target = class_cfg_list.find(item => {
        const { identity: { node_type: identityNodeType } } = item;
        if (node_type === identityNodeType) return true;
      });
      if (!target) {
        target = defaultParagraph({ identity: { node_type } });
        class_cfg_list.push(target);
      }
      this.config = target;

      this.placeholder = mergeDefault(defaultParagraph(), default_style);
      this.actStyle = val;
    },
  },
  watch: {
    tabIndex: {
      handler () {
        this.watchTab();
      }
    }
  }
};
</script>
<style lang="scss">
.style-tool-tooltip {
  overflow: auto;
}
.style-tool-mark {
  font-weight: 700;
  color: #606266;
  background-color: yellow;
}
.style-demo-paragraph-config-pop {
  padding: 0;
  .style-demo-paragraph-config-pop-list {
    /deep/ .el-button--text {
      border: unset;
      background-color: unset;
      color:#198cff;
      font-size: 12px;
      padding: 0;
      min-width: unset;
      &:hover {
        background-color: unset;
        color:#66b1ff;
      }
    }
    .pop-header {
      height: 60px;
      padding: 0 12px 0 23px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e4e4;
      .pop-title {
        font-size: 16px;
        font-weight: 500;
        color: #18191A;
        flex: auto;
      }
      .pop-control {
        flex: none;
      }
    }
    .empty {
      height: 300px;
      line-height: 300px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #616665;
    }
    .pop-list {
      max-height: 300px;
      overflow-y: auto;
      .pop-list-item {
        height: 60px;
        border-bottom: 1px solid #e5e4e4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px 0 23px;
        overflow: hidden;
        .content {
          font-size: 14px;
          font-weight: 400;
          color: #616665;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        /deep/ .el-button--text {
          color: #A2A2A2;
          &:hover {
            color: #d5d9de;
          }
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
  $main-color: #409EFF;
  $danger-color: #F56C6C;
  .paragraph-config {
    .fk-config {
      /deep/ .el-checkbox {
        margin-top: 0;
        margin-bottom: 0;
        /deep/ .el-checkbox__label {
          vertical-align: top;
        }
      }
    }
    /deep/ .el-card__body {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    /deep/ .el-checkbox {
      color: unset;
      max-width: 100%;
      overflow: hidden;
    }
    /deep/ .el-checkbox__label {
      width: unset;
      max-width: 100%;
      line-height: 19px;
      vertical-align: middle;;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }
    .header {
      flex: none;
      display: flex;
      align-items: center;
      .inline-tab {
        /deep/ .tabs-item {
          min-width: 100px;
        }
        /deep/ .slide {
          transition: transform .2s;
        }
      }
    }
    .body {
      margin-top: 4px;
      height: calc(100% - 46px);
      .menu {
        height: 100%;
        border: 1px solid #ccc;
        position: relative;
        .title {
          font-size: 16px;
          font-weight: 500;
          line-height: 60px;
          text-align: center;
          .f-r-btn {
            float: right;
            line-height: 60px;
            margin-right: 20px;
          }
        }
        /deep/ .el-menu-item.is-active::before {
          background-color: unset;
        }
        /deep/ .el-menu-item {
          margin-bottom: 10px;
          background-color: #F2F6FC;
        }
        /deep/ .el-menu-item.is-active {
          background-color: #dae1e8;
        }
        .menu-item {
          display: flex;
          justify-content: space-between;
          .del-buton {
            background-color: unset;
            padding: 0;
            color: #A2A2A2;
            min-width: unset;
            font-size: 12px;
            border: none;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
      /deep/ .splitter-pane-resizer {
        background: #fff;
        opacity: 1;
        // border: 1px solid #0003;
        z-index: 1111;
        // box-shadow: 0px 0px 3px #bdbfb4;
      }
      /deep/ .splitter-paneR {
        padding-left: 6px!important;
      }
      /deep/ .splitter-paneL {
        padding-right: 5px!important;
      }
      .node-select-container {
        border: 1px solid #ccc;
        height: 100%;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        .node-condition-container {
          flex: none;
          display: flex;
          padding: 4px;
          .search-container {
            flex: auto;
            border: 1px solid #ccc;
            border-radius: 6px;
            padding: 4px;
            .search-input {
              width: 200px;
            }
          }
          .pop-toggle {
            min-width: unset;
            padding: 12px 12px;
            margin-left: 4px;
          }
        }
        .list {
          padding: 10px 20px;
          flex: auto;
          overflow-x: hidden;
          overflow-y: auto;
          .list-header {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            .list-header-left {
              padding-left: 10px;
              flex: auto;
            }
            .list-header-right {
              flex: none;
              display: flex;
              .style-tool-button--text {
                margin-right: 10px;
                color: rgb(48, 49, 51);
              }
              .main-color {
                color: $main-color
              }
              /deep/ .el-checkbox__label {
                width: unset;
                padding-right: 14px;
              }
            }
          }
          .list-container {
            border: 1px solid #DEDEDE;
            flex: auto;
            overflow: hidden;
          }
          .list-item {
            font-size: 12px;
            height: 50px;
            color: #919599;
            .list-item-path {
              padding-left: 30px;
              width: auto;
              overflow: hidden;
              text-overflow:ellipsis;
              white-space: nowrap;
            }
          }
        }
        .footer {
          flex: none;
          text-align: center;
          padding: 10px;
        }
      }
      .bantch-tabs {
        position: absolute;
        left: 20px;
        top: 0;
        transform: translate(0, -100%);
        width: calc(100% - 21px);
        /deep/ .el-tabs__header {
          margin: 0;
        }
        /deep/ .el-tabs__nav-wrap::after {
          height: 1px;
          background-color: #ccc;
        }
      }
      .style-lib {
        flex: none;
        position: relative;
        max-height: 400px;
        border: 1px solid #ddd;
        .style-lib-tabs {
          padding: 0 20px;
          /deep/ .el-tabs__header {
            margin-bottom: unset;
          }
          /deep/ .el-tabs__item {
            height: 50px;
          }
          .tab-item {
            display: flex;
            flex-direction: column;
            .tab-item-img {
              flex: none;
              height: 30px;
            }
            .tab-item-text {
              flex: none;
              height: 20px;
              line-height: 20px;
              font-size: 12px;
              text-align: center;
            }
          }
        }
        .toggle {
          position: absolute;
          right: -1px;
          top: 0;
          transform: translate(0, -100%);
          border: 1px solid #ccc;
          border-bottom-color: transparent;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          padding: 10px 4px;
          background: #fff;
          cursor: pointer;
          .el-icon-caret-top {
            transition: transform .2s;
          }
        }
        .condition-box {
          display: flex;
          align-items: center;
          color: #333;
          height: 40px;
          overflow: hidden;
          transition: all 0.2s;
          .left {
            flex: auto;
            display: flex;
            align-items: center;
            overflow-x: auto;
            .format-item {
              font-size: 13px;
              padding: 2px 4px;
              padding-top: 0.1px;
              margin-left: 20px;
              color: #666;
              border-radius: 3px;
              cursor: pointer;
              &:hover {
                color: #fff;
                background: $main-color;
              }
            }
            .act-item {
              color: #fff;
              background: $main-color;
            }
          }
          .right {
            flex: none;
            display: flex;
            align-items: center;
            font-size: 13px;
            padding: 0 10px;
            i {
              color: #C0C4CC;
            }
            input {
              outline: none;
              border: none;
              border-bottom: 1px solid #ccc;
              padding-left: 10px;
              width: 120px;
              font-size: 13px;
              color: #919699;
              &::-webkit-input-placeholder {
                color: #DCDFE6;
              }
            }
          }
        }
        .style-box {
          transition: all 0.2s;
          display: flex;
          flex-wrap: wrap;
          padding: 10px;
          max-height: 200px;
          overflow-y: auto;
          .item {
            width: 220px;
            height: 90px;
            margin: 4px;
            border: 1px solid transparent;
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 20px;
            cursor: pointer;
            position: relative;
            .del {
              position: absolute;
              right: 0;
              top: 0;
              transform: translate(50%, -50%);
              z-index: 2;
              display: none;
            }
            .img {
              width: 90%;
              height: 40px;
              flex: none;
            }
            .button-box {
              flex: auto;
              align-items: center;
              justify-content: center;
              display: none;
            }
            .text-box {
              flex: auto;
              align-items: center;
              justify-content: center;
              display: flex;
              .el-icon-success {
                color: $main-color;
                margin-right: 6px;
              }
            }
            &:hover {
              border-color: $main-color;
            }
            &:hover {
              .del {
                display: unset;
              }
              .button-box {
                display: flex;
              }
              .text-box {
                display: none;
              }
            }
          }
          .act-item {
            background-color: #409eff1f;
          }
        }
      }
      .closed {
        border-color: transparent;
        .toggle {
          border: 1px solid #ccc;
          border-radius: 4px;
          .el-icon-caret-top {
            transform: rotate(180deg);
          }
        }
        .condition-box {
          height: 0;
        }
        .style-box {
          overflow: hidden;
          height: 0;
          padding: 0;
        }
        .style-lib-tabs {
          height: 0;
          overflow: hidden;
        }
      }
      .preview {
        flex: none;
        height: 200px;
        background-color: #F2F6FC;
        margin: 20px 0;
        padding: 10px;
      }
      .config {
        flex: auto;
        overflow-y: auto;
        position: relative;
        .right-btn {
          position: absolute;
          right: 0;
          top: 0;
          line-height: 40px;
          z-index: 2;
        }
        /deep/ .el-radio__label {
          width: unset;
        }
        /deep/ .el-checkbox__label {
          width: unset;
        }
        /deep/ .el-form-item {
          margin-bottom: 0;
          padding: 10px;
          margin-right: unset;
        }
        .label, /deep/ .el-form-item__label, /deep/ .el-form-item__content {
          font-size: 14px;
          line-height: 17px;
          color: #000;
        }
        /deep/ .el-form-item__label {
          padding-right: 0;
        }
        /deep/ .el-tabs {
          height: 100%;
          .el-tabs__header {
            padding-right: 80px;
          }
          .el-tabs__content {
            overflow-y: auto;
            height: calc(100% - 55px);
          }
        }
        /deep/ .el-form,form{
          padding-bottom: 50px !important;
        }
      }
    }
  }
  .body-right {
    height: 100%;
    min-height: 600px;
    position: relative;
  }
</style>
